<template>
  <div class="min-h-screen h-full bg-[linear-gradient(143.08deg,#F7FBFF_21.45%,#EEF7FF_78.55%)]">

    <TsHeader data-testid="header" />

    <div>
      <slot />
    </div>

    <TsFooter data-testid="footer" />

  </div>
</template>
